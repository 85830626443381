<div class="login-wrapper">

  <!-- MAIN LOGIN FORM -->
    <form *ngIf="!passwordReset && !forgotPasswordResetBool && !forgotPasswordUpdateBool" class="login" [formGroup]="loginForm" (ngSubmit)="userLogin()">
      <section class="title">
          <h3 class="welcome">Welcome to</h3>
          Intelligent Information Management
      </section>
      <div class="login-group">
          <clr-input-container>
              <label class="clr-sr-only">Username</label>
              <input type="text" class="form-control input-txt" name="userName" formControlName="userName" clrInput placeholder="Username" [ngClass]="{ 'is-invalid': submitted && f.userName.errors }" autocomplete="off" />
              <clr-control-error *ngIf="loginForm.get('userName').errors?.required" class="input-text">Please enter a valid Username.</clr-control-error>
          </clr-input-container>
          <clr-password-container title="Show">
              <label class="clr-sr-only">Password</label>            
              <input type="password" class="form-control input-txt" name="password" formControlName="password" clrPassword placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" autocomplete="off" />            
              <clr-control-error *ngIf="loginForm.get('password').errors?.required" class="input-text">Please enter a valid password.</clr-control-error>
          </clr-password-container>
          <button class="btn btn-link forgot-password-btn input-txt" type="button" (click)="forgotPasswordResetBool = true">Forgot password?</button>
          <button [disabled]="!loginForm.valid || !loginForm.dirty" type="submit" class="btn btn-primary login-button" [clrLoading]="loading">LOGIN</button>
      </div>
  </form>

  <!-- FORGOT PASSWORD RESET FORM -->
  <form *ngIf="forgotPasswordResetBool && !forgotPasswordUpdateBool" class="login" [formGroup]="forgotPasswordResetForm" (ngSubmit)="forgotPasswordReset()">
    <section class="title">
        Password Reset
    </section>
    <div class="login-group">
        <clr-input-container>
            <label class="clr-sr-only">Username</label>
            <input type="text" class="form-control input-txt" name="userNameForgotPassword" formControlName="userNameForgotPassword" clrInput placeholder="Please enter your Username" [ngClass]="{ 'is-invalid': submitted && f.userName.errors }" />
            <clr-control-error *ngIf="forgotPasswordResetForm.get('userNameForgotPassword').errors?.required" class="input-text">Please enter a valid Username.</clr-control-error>
        </clr-input-container>
        <button class="btn btn-link forgot-password-btn input-txt"  type="button" (click)="resetBackButton()"> <clr-icon class="back-icon" shape="angle" size="18"></clr-icon> Back to Log in Page</button>

        <button [disabled]="!forgotPasswordResetForm.valid || !forgotPasswordResetForm.dirty" type="submit" class="btn btn-primary login-button" [clrLoading]="loading">SEND</button>
    </div>
</form>

<!-- FORGOT PASSWORD UPDATE FORM -->
  <form *ngIf="forgotPasswordUpdateBool && !forgotPasswordResetBool" class="login" [formGroup]="forgotPasswordUpdateForm" (ngSubmit)="forgotPasswordUpdate()">
    <section class="title t1">
        Create new password
    </section>
    <div class="login-group createNewPass">
        <clr-input-container *ngIf="userNameNotFoundInLocalStorage">
          <label class="clr-sr-only">Username</label>
          <input type="text" class="form-control input-txt" name="userNameForgotPassword" formControlName="userName" clrInput placeholder="Please enter your Username" />
        </clr-input-container>
        <clr-password-container title="Show">
            <label class="clr-sr-only">New Password</label>
            <input type="password" class="form-control" name="newPasswordForgot" (keydown)="onKeyDown($event)" formControlName="newPasswordForgot" clrPassword placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <clr-control-error *ngIf="forgotPasswordUpdateForm.get('newPasswordForgot').errors?.required">Please enter a valid password.</clr-control-error>
          <clr-control-error *ngIf="forgotPasswordUpdateForm.get('newPasswordForgot').errors?.passwordPolicyValidation">Password does not match password policy.</clr-control-error>
          <clr-control-error *ngIf="forgotPasswordUpdateForm.get('newPasswordForgot').errors?.confirmPasswordCheck">Confirmation Password does not match.</clr-control-error>

        </clr-password-container>
        <div class="passwordStrength" [ngClass]="{'green': checkPassLength(forgotPasswordUpdateForm.get('newPasswordForgot'))}"><cds-icon shape="check-circle"></cds-icon> Min 8 characters, max 14</div>
        <div class="passwordStrength" [ngClass]="{'green': checkPassLowercase(forgotPasswordUpdateForm.get('newPasswordForgot'))}"><cds-icon shape="check-circle"></cds-icon> At least one lowercase</div>
        <div class="passwordStrength" [ngClass]="{'green': checkPassUppercase(forgotPasswordUpdateForm.get('newPasswordForgot'))}"><cds-icon shape="check-circle"></cds-icon> At least one uppercase</div>
        <div class="passwordStrength" [ngClass]="{'green': checkPassNumber(forgotPasswordUpdateForm.get('newPasswordForgot'))}"><cds-icon shape="check-circle"></cds-icon> At least one number</div>
        <div class="passwordStrength" [ngClass]="{'green': checkPassSpecialChars(forgotPasswordUpdateForm.get('newPasswordForgot'))}" style="white-space: nowrap;"><cds-icon shape="check-circle"></cds-icon> One special (!@#$%^&*+=)</div>
        <div class="passwordStrength" [ngClass]="{'green': checkNotBeginsWithNumber(forgotPasswordUpdateForm.get('newPasswordForgot'))}"><cds-icon shape="check-circle"></cds-icon> Not begin with a number</div>
        <div class="passwordStrength" [ngClass]="{'green': checkNotContainsUserName(forgotPasswordUpdateForm.get('newPasswordForgot'))}"><cds-icon shape="check-circle"></cds-icon> Not contain user name</div>
        <clr-password-container title="Show">
            <label class="clr-sr-only">Confirm Password</label>
            <input type="password" class="form-control" name="password" (keydown)="onKeyDown($event)" formControlName="confirmPasswordForgot"  clrPassword placeholder="Confirm Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />

            <clr-control-error *ngIf="forgotPasswordUpdateForm.get('confirmPasswordForgot').errors?.required">Please enter a valid password.</clr-control-error>
            <clr-control-error *ngIf="forgotPasswordUpdateForm.get('confirmPasswordForgot').errors?.confirmPasswordCheck">Confirmation Password does not match.</clr-control-error>

        </clr-password-container>
        <clr-input-container>
            <label class="clr-sr-only">Verification Code</label>
            <input type="text" class="form-control" name="verificationCode" formControlName="verificationToken" clrInput placeholder="Please enter the Verification Token" [ngClass]="{ 'is-invalid': submitted && f.userName.errors }" />
            <clr-control-error *ngIf="forgotPasswordUpdateForm.get('verificationToken').errors">Please enter a valid verification token from your email.</clr-control-error>
        </clr-input-container>
        <button class="btn btn-link forgot-password-btn"  type="button" (click)="resetBackButton()"> <clr-icon class="back-icon" shape="angle" size="18"></clr-icon> Back to Log in Page</button>

        <button [disabled]="!forgotPasswordUpdateForm.get('newPasswordForgot').valid ||
        forgotPasswordUpdateForm.get('confirmPasswordForgot').errors ||
        !forgotPasswordUpdateForm.get('verificationToken').valid" type="submit" class="btn btn-primary" [clrLoading]="loading" style="
        margin-top: -10px;">CREATE</button>
    </div>
</form>

<!-- DEFAULT PASSWORD REST FORM -->
  <form *ngIf="passwordReset && !forgotPasswordResetBool && !forgotPasswordUpdateBool" class="login" [formGroup]="passwordUpdateForm" (ngSubmit)="passwordResetFunction()">
      <section class="title">
          <h3 class="welcome"></h3>
          Create New Password
      </section>
      <div class="login-group createPass">
        <clr-password-container>
            <label class="clr-sr-only">Current Password</label>
            <input type="password" class="form-control" name="password" formControlName="currentPassword_update"  clrPassword placeholder="Current Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <clr-control-error *ngIf="passwordUpdateForm.get('currentPassword_update').errors?.required">Please enter your current password.</clr-control-error>
        </clr-password-container>
        <clr-password-container>
            <label class="clr-sr-only">New Password</label>
            <input type="password" class="form-control" name="password" formControlName="newPassword_update"  clrPassword placeholder="New Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <clr-control-error *ngIf="passwordUpdateForm.get('newPassword_update').errors?.required">Please enter a valid password.</clr-control-error>
            <clr-control-error *ngIf="passwordUpdateForm.get('newPassword_update').errors?.passwordPolicyValidation">Password does not match password policy.</clr-control-error>
        </clr-password-container>
        <div class="passwordStrength" [ngClass]="{'green': checkPassLength(passwordUpdateForm.get('newPassword_update'))}"><cds-icon shape="check-circle"></cds-icon> Must be at least 6 characters</div>
        <div class="passwordStrength" [ngClass]="{'green': checkPassLowercase(passwordUpdateForm.get('newPassword_update'))}"><cds-icon shape="check-circle"></cds-icon> Must contain at least one lowercase</div>
        <div class="passwordStrength" [ngClass]="{'green': checkPassUppercase(passwordUpdateForm.get('newPassword_update'))}"><cds-icon shape="check-circle"></cds-icon> Must contain at least one uppercase</div>
        <div class="passwordStrength" [ngClass]="{'green': checkPassNumber(passwordUpdateForm.get('newPassword_update'))}"><cds-icon shape="check-circle"></cds-icon> Must contain at least one number</div>

        <clr-password-container>
            <label class="clr-sr-only">Confirm Password</label>
            <input type="password" class="form-control" name="password" formControlName="confirmPassword_update"  clrPassword placeholder="Confirm Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />

            <clr-control-error *ngIf="passwordUpdateForm.get('confirmPassword_update').errors?.required">Please enter a valid password.</clr-control-error>
            <clr-control-error *ngIf="passwordUpdateForm.get('confirmPassword_update').errors?.confirmPasswordCheck">Confirmation Password does not match.</clr-control-error>

        </clr-password-container>
        <div class="error">
            Invalid user name or password
        </div>
        <button [disabled]="!passwordUpdateForm.valid || !passwordUpdateForm.dirty || (passwordUpdateForm.get('newPassword_update').value  != passwordUpdateForm.get('confirmPassword_update').value)" type="submit" class="btn btn-primary" [clrLoading]="loading">CONFIRM</button>
      </div>
  </form>
</div>

<app-client-selection-modal [modalEnabled]="clientModalEnabled" ></app-client-selection-modal>
